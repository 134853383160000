@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'flatpickr/dist/flatpickr.css';
@import '@simonwep/pickr/dist/themes/nano.min.css';
@import '@ctrl/ngx-emoji-mart/picker';
@import '~quill/dist/quill.snow.css';
@import 'quill-emoji/dist/quill-emoji.css';
@import 'highlight.js/styles/atom-one-light.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/shift-away.css';

:root {
  --1flow-black: #000;
  --1flow-white: #fff;
  --1flow-grey-light: #c4c4c4;
  --1flow-blue: #2d4eff;
  --1flow-red: #cf1322;
  --1flow-ai: #5538ee;

  --transition-delay-base: 50ms;
  --transition-duration-base: 300ms;
  --transition-delay-slow: 150ms;
  --transition-duration-slow: 600ms;
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.web-app-link {
  color: var(--1flow-blue);
}

input.cust-date,
input.cust-date::placeholder {
  font-weight: 600 !important;
  font-size: 13px !important;
}

.date-picker-wrapper {
  z-index: 9999999999999999999999999 !important;
}

.date-picker-wrapper .time {
  display: none !important;
}

body {
  font-size: 12px;
  font-family: Inter;
}

.text-gray {
  color: gray;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.w-50px {
  width: 50px;
}

.h-50px {
  height: 50px;
}

.is-2 {
  font-size: 2rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.outer-card {
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}

.br-8px {
  border-radius: 8px;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.br-2rem {
  border-radius: 2rem;
}

.brtl-2rem {
  border-top-left-radius: 2rem;
}

.brtr-2rem {
  border-top-right-radius: 2rem;
}

.custom-error-style {
  background: transparent !important;
  text-align: right;
  font-size: 12px;
  padding-top: 5px !important;
  color: red !important;
  padding-bottom: 0px !important;
  height: 10px;
  border: 0px !important;
}

.survey .custom-error-style {
  position: absolute !important;
  right: 0px;
}

.br-black {
  border: 1px solid #000 !important;
}

@media (max-width: 1200px) {
  .pt-40 {
    padding-top: 40px !important;
  }
}

.pt-40 {
  padding-top: 40px !important;
}

.iti {
  display: block !important;
}

.text-right {
  text-align: right;
}

.mr-10 {
  margin-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.avgtext {
  color: #b5b5c3 !important;
}

.ms-3rem {
  margin-left: 3rem;
}

.bedge-light2 {
  color: #b5b5c3 !important;
}

.br-3 {
  border-radius: 3rem !important;
}

.sorting_1 {
  background: #f5f8fabf;
}

.bg-secondary-dark {
  background-color: #c4c4c4 !important;
}

.lh-xxl-1 {
  line-height: 3.25 !important;
}

.fs-custom-12px {
  font-size: 12px;
}

.bg-grey {
  background-color: #f4f7f9;
}

/*----------------------Global Use Style End------------------*/

/* ----------------Login Componet Style-------------*/

.or-parent {
  position: relative;
}

.or::before {
  left: 0px;
}

.or::after {
  right: 0px;
}

.or::after,
.or::before {
  content: '';
  width: 47%;
  border-bottom: 1px solid #000;
  position: absolute;
  top: 7px;
  height: 0px;
}

/* ----------------Login Componet Style End-------------*/

.sidebar-logo {
  height: 37px;
}

div#kt_content {
  min-height: 100vh;
}

@font-face {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular';
  src: url('./assets/fonts/FontsFree-Net-SFProDisplay-Regular.eot');
  src:
    local('☺'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf')
      format('truetype'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Semibold';
  src: url('./assets/fonts/FontsFree-Net-SFProDisplay-Semibold.eot');
  src:
    local('☺'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Semibold.woff')
      format('woff'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf')
      format('truetype'),
    url('./assets/fonts/FontsFree-Net-SFProDisplay-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

body#kt_body {
  background: #f8f9fa;
}

$primary-color: rgba(45, 78, 255, 1);
$gray: #b5b5c3;
$primary-color-50: rgba(45, 78, 255, 0.16);
$text-primary: rgba(45, 78, 255, 1);
.text-primary {
  color: $text-primary;
}

.bg-primary-50 {
  background-color: $primary-color-50;
}

.bg-primary {
  background-color: $primary-color;
}

.text-white {
  color: #fff;
}

.link-primary {
  color: $primary-color;
}

.light {
  color: $gray;
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 249, 255, var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: $primary-color;
  color: #fff;
}

.hover\:bg-primary-700:hover {
  --tw-bg-opacity: 1;
  background-color: #206a97;
}

.hover\:bg-light-gray-hv:hover {
  background: #666;
}

.align-items-center {
  align-items: center;
}

.bg-light-gray {
  background: #ccc;
}

.bg-gray {
  background: #f5f8fa !important;
}

.bottom {
  position: fixed;
  width: 370px;
  bottom: 0;
  left: 6%;
  right: 0px;
}

.f-18 {
  font-size: 18px;
}

.bg-dark-blue {
  background: #2d4eff;
}

.bg-light-white {
  background: #e5e5e5;
  color: #5d5fef;
}

.hover\:effect:hover {
  top: -5px;
  right: -5px;
  position: relative;
}

.access button svg {
  margin-top: 9px;
  margin-left: 7px;
}

.w-456 {
  width: 456px;
}

/*-----------------------------DEVELOPER CSS ------------------------------*/

.d-none {
  display: none;
}

/*---New Css----*/

.container {
  max-width: 1088px;
}

.lightgreen-border {
  animation: pulse 2s ease 0s infinite;
}

.toggle a:hover {
  background: #f3f4f6 !important;
}

.bg-dark {
  background: #1e2530;
}

@keyframes sonarWave {
  from {
    opacity: 0.5;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  60% {
    transform: scale(2);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dismiss {
  cursor: pointer;
}

$breakpoint-tablet: 600px;
@media (max-width: $breakpoint-tablet) {
  .w-456 {
    width: 94% !important;
    margin: 0 auto !important;
  }
  .w-456 .px-6 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .max-dev-w {
    width: auto !important;
  }
  .hide-mb {
    display: none;
  }
  .xs\:m-2-t {
    margin-top: 0.75rem;
  }
  .container {
    max-width: 95%;
  }
  .xs\:p-l-0 {
    padding-left: 0 !important;
  }
  .xs\:text-xl-2 {
    font-size: 0.95rem;
    line-height: 1rem;
  }
  .xs\:p-lr-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .xs\:p-x-2 {
    padding-left: 0.55rem !important;
    padding-right: 0.55rem !important;
  }
  .xs\:full {
    width: 100% !important;
  }
  .xs\:full-wrap {
    width: 100% !important;
    flex-wrap: wrap;
  }
  .xs\:w-800 {
    width: 100% !important;
  }
  .xs\:w-800.invite {
    width: auto !important;
  }
  .xs\:p-lr-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .xs\:h-60 {
    height: 60px;
  }
  .xs\:set-bg {
    top: 15px;
    position: relative;
  }
  .w-586 {
    width: 100% !important;
  }
  .xs\:text-left {
    text-align: left;
  }
  .xs\:pt-4 {
    padding-top: 0.25rem;
  }
  .xs\:wrap-it {
    flex-wrap: wrap;
  }
  .xs\:full-area {
    grid-template-columns: unset !important;
  }
  .xs\:block {
    display: block !important;
  }
  html body .add-filt2 {
    top: 20px !important;
  }
  .survey_filters_dropdown {
    left: 0;
    max-width: 330px;
  }
  .survey-toggle.survey_status.z-30 {
    max-width: 330px;
  }
  input[type='text'] {
    height: 44px;
    font-size: 12px;
  }
  .xs\:rg-0 {
    right: 0;
  }
  .xs\:padding-15 {
    padding: 0 15px;
  }
  .xs\:h-25 {
    height: 25px !important;
  }
  .xs\:m-b-20 {
    margin-bottom: 20px !important;
  }
  .one-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .one-line-ver-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .xs\:m-t-0 {
    top: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
  }
  .xs\:w-96 {
    width: 342px;
  }
  .xs\:centr {
    justify-content: center;
  }
  .xs\:bottom {
    margin-bottom: 20px;
  }
  .xs\:d-block {
    display: inline-block !important;
  }
  .xs\:wrap {
    flex-wrap: wrap !important;
  }
}

.survey-preview .background {
  background: #c4c4c4;
  height: 100%;
}

.survey-preview .cursor-pointer {
  cursor: pointer;
}

/*--------------------for survey preview popup --------------------*/

$primary-color-survey-preview-mobile: rgba(95, 95, 239, 1);
.survey-preview-mobile-container .bg-primary {
  background-color: $primary-color-survey-preview-mobile;
  color: #fff;
}

.survey-preview-mobile-container h2 {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Semibold';
  font-size: 18px !important;
  text-align: left;
}

.survey-preview-mobile-container p {
  font-size: 14px;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular';
  color: #50555c;
  line-height: 16.71px;
  letter-spacing: 0.5px;
  text-align: left;
}

.survey-preview-mobile-container .bg-gray-bk {
  background: rgba(0, 0, 0, 0.25);
}

.survey-preview-mobile-container .rounded-t-3xl {
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}

.survey-preview-mobile-container .small-size {
  width: 100%;
  margin: 0 auto;
  max-width: 360px;
}

/*
.survey-preview-mobile-container .ob1_none,.survey-preview-mobile-container .ob2_none{display:none;}
.survey-preview-mobile-container .ob1_op,.survey-preview-mobile-container .ob1_op,.survey-preview-mobile-container .ob2_op{opacity: 0;}
.survey-preview-mobile-container .ob2_op{opacity: 0;}
.survey-preview-mobile-container .obj3_op{opacity: 0;}
.survey-preview-mobile-container .w-screen.h-601.relative,*/

.survey-preview-mobile-container .w-screen.h-590.relative {
  bottom: -1300px;
}

.survey-preview-mobile-container .h-500 {
  height: 937px;
  margin-top: 0px;
}

.survey-preview-mobile-container .h-601 {
  height: 937px;
  max-width: 360px;
}

.survey-preview-mobile-container .h-600 {
  height: 900px;
  max-width: 360px;
  overflow: hidden;
}

.survey-preview-mobile-container .border-5 {
  top: unset !important;
  height: 5px;
  width: 44px;
  border-radius: 6px;
  margin: 0 auto;
  position: relative;
  bottom: 17px;
  left: 0 !important;
  right: 0;
  background: #f8f9fa;
  border-width: 0;
}

.survey-preview-mobile-container .border-9 {
  top: unset !important;
  height: 5px;
  width: 44px;
  border-radius: 6px;
  margin: 0 auto;
  position: relative;
  bottom: 15px;
  left: 0 !important;
  right: 0;
  background: #f8f9fa;
  border-width: 0;
  opacity: 0;
}

.survey-preview-mobile-container .px-25 {
  padding-left: 0.65rem;
  padding-right: 0.65rem;
}

.survey-preview-mobile-container .bdr-1 {
  bottom: 276px;
}

.survey-preview-mobile-container .bdr-2 {
  bottom: 291px;
}

.survey-preview-mobile-container .bdr-3 {
  bottom: 286px;
}

.survey-preview-mobile-container .border-4 {
  bottom: 235px;
}

.survey-preview-mobile-container .border-6 {
  bottom: 314px;
}

.survey-preview-mobile-container .border-7 {
  bottom: 406px;
}

.survey-preview-mobile-container .border-8 {
  bottom: 446px;
}

.survey-preview-mobile-container .w3-border {
  background-color: #c4c4c4 !important;
}

.survey-preview-mobile-container .w3-grey,
.survey-preview-mobile-container .w3-grey1,
.survey-preview-mobile-container .w3-grey2,
.survey-preview-mobile-container .w3-grey3,
.survey-preview-mobile-container .w3-grey4,
.survey-preview-mobile-container .w3-grey5,
.survey-preview-mobile-container .w3-grey6,
.survey-preview-mobile-container .w3-grey7 {
  background: $primary-color-survey-preview-mobile;
  height: 5px;
}

.survey-preview-mobile-container .hover\:btn-primary-500:hover {
  --tw-bg-opacity: 0.5;
  background-color: rgba(95, 95, 239, var(--tw-bg-opacity));
  color: #fff;
}

.survey-preview-mobile-container .focus\:outline:focus {
  --tw-bg-opacity: 1;
  border: 1px solid $primary-color-survey-preview-mobile;
}

.survey-preview-mobile-container .focus\:btn-primary:focus {
  --tw-bg-opacity: 1;
  background-color: $primary-color-survey-preview-mobile;
  color: #fff;
}

.survey-preview-mobile-container .font-20 {
  font-size: 25px;
}

.survey-preview-mobile-container .bb {
  stroke: #5d5fef;
}

.survey-preview-mobile-container .hover\:fill:hover {
  --tw-bg-opacity: 0.5;
  fill: #5d5fef;
}

.survey-preview-mobile-container .-top-18 {
  margin-top: -9rem;
}

.survey-preview-mobile-container .rating-active {
  --tw-bg-opacity: 1;
  fill: #f9d206;
  stroke: #f9d206 !important;
}

.survey-preview-mobile-container .rating-active path {
  stroke: #f9d206 !important;
}

.survey-preview-mobile-container #privacy-setting-0-label {
  text-align: left;
}

.survey-preview-mobile-container .rating-active-1 {
  background: #5d5fef;
}

.survey-preview-mobile-container .text-sm {
  font-size: 12px;
}

.survey-preview-mobile-container .focus\:ring-indigo-500:focus + label {
  border: 1px solid $primary-color-survey-preview-mobile;
}

.survey-preview-mobile-container .max-h-50px {
  width: 50px;
  height: 16px;
  object-fit: contain;
}

.survey-preview-mobile-container [type='checkbox']:hover {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: #2563eb;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.survey-preview-mobile-container .text-gray {
  color: #c4c4c4;
  font-size: 11px;
}

.survey-preview-mobile-container #draggable {
  cursor: pointer;
}

.survey-preview-mobile-container .h-590 {
  height: 590px;
  overflow: hidden;
}

.survey-preview-mobile-container .background {
  background: #c4c4c4;
}

.survey-preview-mobile-container .hide-cst {
  display: none;
}

.survey-preview-mobile-container .h-0 {
  height: 0;
}

#ui-datepicker-div {
  display: none;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #78909c;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}

.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #546e7a;
  font-size: 0.875rem;
  text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
  background-color: #e0f2f1;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: $primary-color;
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #eceff1;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #78909c;
  font-weight: 400;
  font-size: 0.75rem;
}

.date-picker-wrapper {
  padding: 0 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}

.date-picker-wrapper .footer {
  display: none !important;
}

html body .date-picker-wrapper .first-date-selected,
html body .date-picker-wrapper .last-date-selected {
  background-color: #5f65c2 !important;
  color: #fff !important;
}

html body .date-picker-wrapper .first-date-selected {
  border-radius: 10px 0 0 10px !important;
}

html body .date-picker-wrapper .last-date-selected {
  border-radius: 0 10px 10px 0 !important;
}

html body .date-picker-wrapper .month-wrapper table .day.checked {
  background-color: #e2e2e2;
}

html body .date-picker-wrapper .month-wrapper table .day.toMonth.hovering {
  background-color: #e2e2e2;
}

html body .date-picker-wrapper .month-wrapper table .day.real-today {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}

html body .date-picker-wrapper .month-wrapper table .day {
  padding: 11px 11px;
}

.font-64 {
  font-size: 64px;
  line-height: 76.38px;
}

/*--------SURVEY RESULT -------------------*/

$sky: #eef4fe;
$org: #fef6eb;
$grn: #ebfdf3;
$pur: #e9e3f8;
$dr-grn: #b6ffdc;
$dr-yellow: #fff0a1;
$red: #ffdede;
.pro:hover {
  text-decoration: underline;
}

.survey-result-container .bg-blue {
  background: #76b3fc;
}

.survey-result-container .bg-pink {
  background: #ffb1d9;
}

.survey-result-container .bg-neon {
  background: #d2fc7a;
}

.survey-result-container .sky {
  background: $sky;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  padding: 0;
}

.survey-result-container .org {
  background: $org;
}

.survey-result-container .grn {
  background: $grn;
}

.survey-result-container .pur {
  background: $pur;
}

.survey-result-container .dr-grn {
  background: $dr-grn;
}

.survey-result-container .dr-yellow {
  background: $dr-yellow;
}

.survey-result-container .red {
  background: $red;
}

.survey-result-container .progress-100 {
  width: 100%;
}

.survey-result-container .progress-80 {
  width: 80%;
}

.survey-result-container .progress-50 {
  width: 50%;
}

.survey-result-container .progress-30 {
  width: 30%;
}

.survey-result-container .progress-20 {
  width: 5%;
}

.survey-result-container .progress-2 {
  width: 1%;
}

.survey-result-container .pro span {
  display: block;
}

.survey-result-container .hv:hover .on-hover {
  display: block;
  cursor: pointer;
}

.survey-result-container .on-hover {
  display: none;
}

.survey-result-container .greendot {
  background: #3eae78;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.survey-result-container .darkdot {
  background: #878787;
}

.survey-result-container .reddot {
  background: #ab1010;
}

.survey-result-container .pos-2 {
  /*top:-3px*/
}

.p-t-3 {
  padding-top: 7px;
}

.tp--1 {
  top: -1px;
}

.tp-1 {
  top: 3px;
}

input#color_code {
  text-transform: uppercase;
}

.survey-result-container .show {
  display: block !important;
}

.survey-result-container .sm\:p-8 {
  padding: 1.25rem 1.25rem;
}

.survey-result-container .dismiss {
  cursor: pointer;
}

.survey-result-container .dismiss svg {
  fill: #fff !important;
  stroke: white;
  opacity: 0.85;
}

.survey-result-container
  h2.text-md.font-medium.text-gray-900.w-1\/12.pl-2.text-right {
  font-size: 14px;
}

.survey-result-container .tab-active {
  color: #4338ca;
  font-weight: 700;
}

.survey-result-container .tab1,
.survey-result-container .tab2,
.survey-result-container .tab3 {
  cursor: pointer;
}

.survey-result-container .focus\:no-focus:focus,
.survey-result-container .no-focus:focus {
  border: none !important;
  box-shadow: none;
}

.survey-result-container div#chart3,
.survey-result-container div#chart {
  height: 300px;
  overflow: hidden;
}

.survey-result-container p.leftvalue {
  padding-left: 7.75rem;
  font-size: 20px;
  font-weight: 400;
  color: #c4c4c4;
}

.survey-result-container p.rightvalue {
  padding-right: 7.75rem;
  font-size: 20px;
  font-weight: 400;
  color: #c4c4c4;
}

$breakpoint-tablet: 800px;
@media (max-width: $breakpoint-tablet) {
  .survey-result-container .container {
    width: 90%;
  }
  .survey-result-container
    .toggle.survey-result-container.py-1.z-30.origin-top-left.absolute.right-0.mt-2.w-60.border.rounded-md.shadow-lg.bg-white.ring-1.ring-black.ring-opacity-5 {
    right: unset !important;
  }
  .survey-result-container
    .toggle.survey-result-container.z-30.origin-top-left.absolute.left-0.mt-2.w-96.rounded-md.shadow-lg.bg-white.ring-1.ring-black.ring-opacity-5 {
    width: 335px;
  }
  .survey-result-container
    .toggle.survey-result-container.z-30.origin-top-left.absolute.left-0.mt-2.w-60.border.rounded-md.shadow-lg.bg-white.ring-1.ring-black.ring-opacity-5 {
    left: unset !important;
    right: 0px;
  }
  .survey-result-container ul.flex.mr-3.text-right.justify-end {
    margin-right: -20px;
  }
  .survey-result-container .xs\:full-area {
    width: 100%;
  }
  .survey-result-container .apexcharts-datalabels-group text,
  .apexcharts-datalabels-group {
    font-size: 30px !important;
  }
}

$breakpoint-tablet2: 600px;
@media (max-width: $breakpoint-tablet2) {
  .xs\:ml-2 {
    margin-left: 0.5rem;
  }
  .survey-result-container .w-11\/12 {
    width: 76.666667%;
  }
  .survey-result-container .w-1\/12 {
    width: 23.333333%;
  }
  .survey-result-container h2.text-xl.w-11\/12.font-bold.mr-5.text-gray-700 {
    width: 100%;
  }
  .survey-result-container .xs\:w-40 {
    width: 30% !important;
    float: left;
  }
  .survey-result-container .xs\:w-60 {
    width: 50% !important;
    float: right;
  }
  .survey-result-container div#chart3,
  .survey-result-container div#chart {
    height: 200px;
  }
  .survey-result-container p.leftvalue {
    padding-left: 3.75rem;
  }
  .survey-result-container p.rightvalue {
    padding-right: 4.75rem;
  }
  .cc {
    width: 100%;
  }
  .crst,
  .crs2 {
    opacity: 1 !important;
    background-image: linear-gradient(
      to right,
      rgba(244, 243, 244, 0.75),
      rgba(244, 243, 244, 1)
    ) !important;
  }
  .crst path,
  .crs2 path {
    fill: #000;
  }
}

$breakpoint-tablet3: 450px;
@media (max-width: $breakpoint-tablet3) {
  .survey-result-container div#chart3,
  .survey-result-container div#chart {
    height: 100px;
  }
  .survey-result-container p.leftvalue {
    padding-left: 1.75rem;
  }
  .survey-result-container p.rightvalue {
    padding-right: 2.75rem;
  }
  .w-387 {
    width: 330px !important;
    padding: 0 !important;
    margin: 10px auto !important;
  }
  html body .rating_btn button.w-1\/5 {
    width: 54px;
    height: 54px;
    padding: 7px;
    line-height: 41px;
  }
  html body .top-bar:before {
    width: 330px !important;
    left: -20px !important;
  }
  html body .rating_btn button.w-1\/10 {
    width: 24.5px;
  }
  .cc {
    width: 100%;
  }
  html body svg.bb.w-12 {
    width: 2.45rem;
  }
  .cc .px-7 {
    padding-left: 1.25rem !important;
    padding-right: 1rem !important;
  }
  .br-l {
    width: 300px !important;
    letter-spacing: -0.55px !important;
  }
  .data-logic-info {
    font-size: 11px !important;
  }
  b.ml-1.data-rule-save {
    font-size: 10px;
    margin-top: 3px;
  }
  b.mr-1.data-rule-save {
    font-size: 10px;
    margin-top: 1px;
  }
  .w-266 {
    width: 280px !important;
  }
  svg.icon-x {
    bottom: 13px !important;
  }
  .firstseen-info:hover .crs2,
  .country-rule-info:hover .crs2,
  .event-rule-info:hover .crs2,
  .page-rule-info:hover .crs2,
  .time-rule-info:hover .crs2 {
    background: #dbe1ff !important;
  }
}

.survey-result-container .apexcharts-menu-icon {
  display: none !important;
}

.text-md {
  font-size: 1rem;
}

.pointer {
  cursor: pointer;
}

.first-capital::first-letter {
  text-transform: capitalize;
}

/*browser css start*/

.mockup-phone {
  display: inline-block;
  border: 4px solid #444;
  border-radius: 50px;
  background-color: #000;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (max-width: 450px) {
    width: calc(100% - 6%);
  }
}

.mockup-phone .camera {
  position: relative;
  top: 0;
  left: 0;
  background: #000;
  height: 25px;
  width: 150px;
  margin: 0 auto;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  z-index: 11;
}

.mockup-phone .camera:before {
  content: '';
  position: absolute;
  top: 35%;
  left: 50%;
  width: 50px;
  height: 4px;
  border-radius: 5px;
  background-color: #0c0b0e;
  transform: translate(-50%, -50%);
}

.mockup-phone .camera:after {
  content: '';
  position: absolute;
  top: 20%;
  left: 70%;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #0f0b25;
}

.mockup-phone .display {
  overflow: hidden;
  border-radius: 40px;
  margin-top: -25px;
}

.artboard.phone-1 {
  aspect-ratio: 10.5/20;

  @media screen and (max-width: 1440px) {
    aspect-ratio: 12/20;
  }

  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
}

.mockup-window {
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  padding-top: 1.25rem;
  border-radius: var(--rounded-box, 1rem);
  height: 71vh;

  @media screen and (max-width: 1440px) {
    height: 80vh;
  }
}

.mockup-window:before {
  content: '';
  border-radius: 9999px;
  display: block;
  height: 0.75rem;
  margin-bottom: 1rem;
  opacity: 0.3;
  width: 0.75rem;
  box-shadow:
    1.4em 0,
    2.8em 0,
    4.2em 0;
}

/*Browser css end*/

/*-----------Landing v2 Page CSS start-----------*/

.landing-v-2 .container {
  max-width: 852px;
  padding: 0;
}

.landing-v-2 .slide-highlight {
  color: #7000ff;
}

.landing-v-2 .container-lg {
  max-width: 976px;
  padding: 0;
}

.landing-v-2 .list-point {
  font-size: 18px;
  line-height: 25.48px;
  list-style: none;
  padding: 0;
  margin-bottom: 37px;
}

.landing-v-2 .mb-33 {
  margin-bottom: 33px;
}

.landing-v-2 .mb-44 {
  margin-bottom: 44px;
}

.landing-v-2 .mb-87 {
  margin-bottom: 87px;
}

.landing-v-2 .mb-23 {
  margin-bottom: 23px;
}

.landing-v-2 .mt-128 {
  margin-top: 128px;
}

.landing-v-2 .mt-62 {
  margin-top: 62px;
}

.landing-v-2 .mt-38 {
  margin-top: 38px;
}

.landing-v-2 .text-primary {
  color: #2d4eff !important;
}

.landing-v-2 .content-part h2 {
  font-size: 32px;
  line-height: 48px;
}

.landing-v-2 .f-18 {
  font-size: 18px;
  line-height: 27px;
}

.landing-v-2 .f-50 {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 37px;
}

.landing-v-2 .f-22 {
  font-size: 22px;
  line-height: 26.25px;
}

.landing-v-2 .f-18-l {
  font-size: 18px;
  line-height: 21.48px;
}

.landing-v-2 .content-part p.f-18 {
  position: relative;
  padding-left: 30px;
}

.landing-v-2 .content-part span.icon {
  position: absolute;
  left: 0;
  top: 2px;
}

.landing-v-2 .f-600 {
  font-weight: 600;
  line-height: 33px;
}

.landing-v-2 span.blue {
  color: #2d4eff;
}

.landing-v-2 span.pink {
  color: #ef5da8;
}

.landing-v-2 span.yellow {
  color: #f9d206;
}

.landing-v-2 .blue-box {
  background: #5d5fef;
  border-radius: 30px;
  border: 5px solid #000;
  padding-bottom: 74px;
  height: 320px;
}

.landing-v-2 .blue-box h3 {
  font-size: 30px;
  line-height: 33px;
}

.landing-v-2 .area-txt {
  max-width: 840px;
  margin: 0 auto;
}

.landing-v-2 .blank {
  height: 300px;
}

.landing-v-2 a.btn {
  width: 267px;
  padding: 17px 0 !important;
  font-size: 18px;
  box-shadow: 1px 1px 1px #000 !important;
}

.landing-v-2 p {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular';
}

.landing-v-2 h1,
.landing-v-2 h2,
.landing-v-2 h3,
.landing-v-2 h4,
.landing-v-2 h5,
.landing-v-2 h6 {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Semibold';
}

.landing-v-2 a.btn.btn-white {
  color: #00a3ff;
  transition: all 0.15s ease-in;
}

.landing-v-2
  .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: 1px 4px 3px 0px rgb(0 0 0 / 34%) !important;
}

.landing-v-2 a.btn.btn-primary {
  background: #2d4eff;
}

.landing-v-2 a.btn.btn-white:hover {
  margin-top: -5px;
  margin-right: -5px;
}

.landing-v-2 input.form-control {
  border: 2px solid #2d4eff;
  height: 83px;
  border-radius: 10px;
}

.landing-v-2 .rel {
  position: relative;
}

.landing-v-2 .form-group.rel button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 62px;
  background: #2d4eff;
  width: 267px;
  font-size: 20px;
  font-weight: 400;
}

.landing-v-2 .form-group.rel button:hover {
  background: #112bb7;
}

.landing-v-2 .form-group.rel .form-control {
  font-size: 26px;
  font-weight: 400;
  color: #878787;
}

.landing-v-2 .purple {
  background: #5d5fef !important;
}

.landing-v-2 .purple:hover {
  background: #3436d0 !important;
}

.landing-v-2 .purple-border {
  border-color: #5d5fef !important;
}

/*-----------add media screen for responsive survey result page-----------*/

@media (max-width: 1024px) {
  .container {
    max-width: 100%;
  }
}

@media (max-width: 728px) {
  .modal-event-cancel,
  .modal-location-cancel,
  .modal-device-cancel,
  .modal-connect-cancel {
    margin-left: 0;
    margin-top: 10px;
  }
  button.survey-result-container .pos-2 {
    top: -3px;
    margin-left: 0;
  }
  .add-filt {
    top: -24px;
  }
  .add-filt2 {
    top: 61px !important;
    width: 100% !important;
    justify-content: start !important;
    margin: 0 !important;
    width: 111px !important;
    max-width: 100% !important;
    min-width: 100px !important;
  }
  .add-filt2 button {
    width: 100% !important;
    justify-content: start !important;
    margin: 0 !important;
  }
}

/*-----------add media screen for responsive survey result page-----------*/

@media (max-width: 980px) {
  .landing-v-2 .container-lg,
  .landing-v-2 .container {
    max-width: 90% !important;
  }
  .landing-v-2 .blue-box {
    padding-bottom: 20px;
    height: auto;
  }
  .landing-v-2 .form-group.rel .form-control {
    font-size: 20px;
    margin: 0 2%;
    width: 98%;
  }
}

@media (max-width: 570px) {
  .landing-v-2 .f-18-l {
    padding: 0 20px;
    font-size: 16px;
    line-height: 18.48px;
  }
  header {
    padding: 17px 17px;
  }
  .landing-v-2 .mt-128 {
    margin-top: 60px;
  }
  .landing-v-2 .f-18 {
    font-size: 16px;
    line-height: 25px;
  }
  .landing-v-2 .f-50 {
    font-weight: bold;
    font-size: 45px;
    line-height: 50px;
  }
  .landing-v-2 .f-22 {
    font-size: 18px;
    line-height: 22.25px;
  }
  .landing-v-2 .content-part h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .landing-v-2 .blue-box {
    height: auto;
  }
  .landing-v-2 .form-group.rel .form-control {
    font-size: 20px;
    margin: 0 1%;
    width: 98%;
  }
  .landing-v-2 .form-group.rel button {
    position: relative;
    float: right;
    margin: 0px -5px 30px 40px;
  }
  .landing-v-2 .d-block-i {
    display: inline-block;
  }
  .landing-v-2 .purple {
    background: #d8d8d8 !important;
    color: #000;
  }
  .landing-v-2 .purple svg path {
    fill: #000;
  }
}

.landing-v-2 .font-italic {
  font-style: italic;
}

.landing-v-2 .list-point li {
  font-weight: 400;
}

/*-----------Landing v2 Page CSS end-----------*/

/*-----------------Landing V3 Page CSS start------------------*/

.landing-v-3 .container {
  max-width: 852px;
  padding: 0;
}

.landing-v-3 .slide-highlight {
  color: #7000ff;
}

.landing-v-3 .container-lg {
  max-width: 976px;
  padding: 0;
}

.landing-v-3 .list-point {
  font-size: 18px;
  line-height: 25.48px;
  list-style: none;
  padding: 0;
  margin-bottom: 37px;
}

.landing-v-3 .mb-33 {
  margin-bottom: 33px;
}

.landing-v-3 .mb-44 {
  margin-bottom: 44px;
}

.landing-v-3 .mb-87 {
  margin-bottom: 87px;
}

.landing-v-3 .mb-23 {
  margin-bottom: 23px;
}

.landing-v-3 .mt-128 {
  margin-top: 128px;
}

.landing-v-3 .mt-62 {
  margin-top: 62px;
}

.landing-v-3 .mt-38 {
  margin-top: 38px;
}

.landing-v-3 .text-primary {
  color: #2d4eff !important;
}

.landing-v-3 .content-part h2 {
  font-size: 32px;
  line-height: 48px;
}

.landing-v-3 .f-18 {
  font-size: 18px;
  line-height: 27px;
}

.landing-v-3 .f-50 {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 37px;
}

.landing-v-3 .f-22 {
  font-size: 22px;
  line-height: 26.25px;
}

.landing-v-3 .f-18-l {
  font-size: 18px;
  line-height: 21.48px;
}

.landing-v-3 .content-part p.f-18 {
  position: relative;
  padding-left: 30px;
}

.landing-v-3 .content-part span.icon {
  position: absolute;
  left: 0;
  top: 2px;
}

.landing-v-3 .f-600 {
  font-weight: 600;
  line-height: 33px;
}

.landing-v-3 span.blue {
  color: #2d4eff;
}

.landing-v-3 span.pink {
  color: #ef5da8;
}

.landing-v-3 span.yellow {
  color: #f9d206;
}

.landing-v-3 .blue-box {
  background: #5d5fef;
  border-radius: 30px;
  border: 5px solid #000;
  padding-bottom: 74px;
  height: 320px;
}

.landing-v-3 .blue-box h3 {
  font-size: 30px;
  line-height: 33px;
}

.landing-v-3 .area-txt {
  max-width: 840px;
  margin: 0 auto;
}

.landing-v-3 .blank {
  height: 300px;
}

.landing-v-3 a.btn {
  width: 267px;
  padding: 17px 0 !important;
  font-size: 18px;
  box-shadow: 1px 1px 1px #000 !important;
}

.landing-v-3 p {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular';
}

.landing-v-3 h1,
.landing-v-3 h2,
.landing-v-3 h3,
.landing-v-3 h4,
.landing-v-3 h5,
.landing-v-3 h6 {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Semibold';
}

.landing-v-3 a.btn.btn-white {
  color: #00a3ff;
  transition: all 0.15s ease-in;
}

.landing-v-3
  .btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: 1px 4px 3px 0px rgb(0 0 0 / 34%) !important;
}

.landing-v-3 a.btn.btn-primary {
  background: #2d4eff;
}

.landing-v-3 a.btn.btn-white:hover {
  margin-top: -5px;
  margin-right: -5px;
}

.landing-v-3 input.form-control {
  border: 2px solid #2d4eff;
  height: 83px;
  border-radius: 10px;
}

.landing-v-3 .rel {
  position: relative;
}

.landing-v-3 .form-group.rel button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 62px;
  background: #2d4eff;
  width: 267px;
  font-size: 20px;
  font-weight: 400;
}

.landing-v-3 .form-group.rel button:hover {
  background: #112bb7;
}

.landing-v-3 .form-group.rel .form-control {
  font-size: 26px;
  font-weight: 400;
  color: #878787;
}

.landing-v-3 .purple {
  background: #5d5fef !important;
}

.landing-v-3 .purple:hover {
  background: #3436d0 !important;
}

.landing-v-3 .purple-border {
  border-color: #5d5fef !important;
}

@media (max-width: 980px) {
  .landing-v-3 .container-lg,
  .landing-v-3 .container {
    max-width: 90% !important;
  }
  .landing-v-3 .blue-box {
    padding-bottom: 20px;
    height: auto;
  }
  .landing-v-3 .form-group.rel .form-control {
    font-size: 20px;
    margin: 0 2%;
    width: 98%;
  }
}

@media (max-width: 570px) {
  .landing-v-3 .f-18-l {
    padding: 0 20px;
    font-size: 16px;
    line-height: 18.48px;
  }
  header {
    padding: 17px 17px;
  }
  .landing-v-3 .mt-128 {
    margin-top: 60px;
  }
  .landing-v-3 .f-18 {
    font-size: 16px;
    line-height: 25px;
  }
  .landing-v-3 .f-50 {
    font-weight: bold;
    font-size: 45px;
    line-height: 50px;
  }
  .landing-v-3 .f-22 {
    font-size: 18px;
    line-height: 22.25px;
  }
  .landing-v-3 .content-part h2 {
    font-size: 26px;
    line-height: 36px;
  }
  .landing-v-3 .blue-box {
    height: auto;
  }
  .landing-v-3 .form-group.rel .form-control {
    font-size: 20px;
    margin: 0 1%;
    width: 98%;
  }
  .landing-v-3 .form-group.rel button {
    position: relative;
    float: right;
    margin: 0px -5px 30px 40px;
  }
  .landing-v-3 .d-block-i {
    display: inline-block;
  }
  .landing-v-3 .purple {
    background: #d8d8d8 !important;
    color: #000;
  }
  .landing-v-3 .purple svg path {
    fill: #000;
  }
}

.landing-v-3 .font-italic {
  font-style: italic;
}

/*-----------------------Landing v3 page css END--------------*/

/*-----------------DESKTOP POPUP PAGE CSS START------------------*/

$primary-color-desktop: rgba(95, 95, 239, 1);

.desktop-popup .bg-primary {
  background-color: $primary-color-desktop;
  color: #fff;
}

.desktop-popup h2 {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Semibold';
  font-size: 18px !important;
}

.desktop-popup .bg-gray {
  background: #999999;
}

.desktop-popup .small-size {
  width: 380px;
  margin: 0 auto;
}

.desktop-popup .h-500 {
  height: 812px;
  margin-top: 80px;
}

.desktop-popup .h-600 {
  height: 792px;
}

.desktop-popup .border-5 {
  border: 5px solid #c4c4c4;
  width: 44px;
  border-radius: 6px;
  margin: 0 auto;
  position: absolute;
  bottom: 305px;
  left: 0;
  right: 0;
  background: #c4c4c4;
}

.desktop-popup .px-25 {
  padding-left: 0.65rem;
  padding-right: 0.65rem;
}

.desktop-popup .border-4 {
  bottom: 215px;
}

.desktop-popup .border-6 {
  bottom: 320px;
}

.desktop-popup .border-7 {
  bottom: 460px;
}

.desktop-popup .border-8 {
  bottom: 500px;
}

.desktop-popup .w3-border {
  background-color: #c4c4c4 !important;
}

.desktop-popup .w3-grey {
  background: $primary-color-desktop;
  height: 5px;
  width: 0%;
}

.desktop-popup .hover\:btn-primary-500:hover {
  --tw-bg-opacity: 0.5;
  background-color: rgba(95, 95, 239, var(--tw-bg-opacity));
  color: #000;
}

.desktop-popup .focus\:outline:focus {
  --tw-bg-opacity: 1;
  border: 1px solid $primary-color-desktop;
}

.desktop-popup .focus\:btn-primary:focus {
  --tw-bg-opacity: 1;
  background-color: $primary-color-desktop;
  color: #fff;
}

.desktop-popup .radius-10 {
  border-radius: 5px !important;
}

.desktop-popup .font-20 {
  font-size: 25px;
}

.desktop-popup .bb {
  stroke: #5d5fef;
}

.desktop-popup .top-un {
  top: unset;
  bottom: 1rem;
}

.desktop-popup .hover\:fill:hover {
  --tw-bg-opacity: 0.5;
  fill: #5d5fef;
}

.desktop-popup .hover\:fill:hover path {
  stroke: #5d5fef;
}

.desktop-popup .-top-18 {
  margin-top: -9rem;
}

.desktop-popup .rating-active {
  --tw-bg-opacity: 1;
  fill: #f9d206;
}

.desktop-popup .rating-active path {
  stroke: #f9d206 !important;
}

.desktop-popup .rating-active-1 {
  background: #5d5fef;
}

.desktop-popup .text-sm {
  font-size: 14px;
  text-align: left;
}

.desktop-popup .focus\:ring-indigo-500:focus + label {
  border: 1px solid $primary-color-desktop;
}

.desktop-popup .max-h-50px {
  width: 50px;
  height: 16px;
  object-fit: contain;
}

.desktop-popup p.light {
  color: #c4c4c4;
}

.desktop-popup .border-2 {
  border: 2px solid $primary-color-desktop;
}

.desktop-popup .heading {
  color: #50555c;
  text-align: center;
  opacity: 1;
  position: absolute;
  width: 100%;
  font-size: 50px !important;
}

.desktop-popup .mt-10 {
  margin-top: 10rem;
  border-radius: 10px;
}

.desktop-popup [type='checkbox']:hover {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: #2563eb;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.desktop-popup .sidebar-logo {
  opacity: 0.5;
}

.desktop-popup .opacity-1 {
  opacity: 1 !important;
}

.desktop-popup .p-x-3 {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

/*-----------------DESKTOP POPUP PAGE CSS END ------------------*/

/*-------- 10/1/2021 changes for star z-index------------*/

.tab9-content {
  position: relative;
  z-index: 28;
}

/*-------- 10/1/2021 changes for star z-index------------*/

/*---------------------------------------Mobile navigation ------------------------*/

/* The side navigation menu */

#mobile-menu .sidenav {
  height: 100%;
  /* 100% Full-height */
  width: 300px;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Stay on top */
  top: 0;
  left: -310px;
  background-color: #fff;
  /* Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */

#mobile-menu .sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.5s;
  // left:-30px;
}

#mobile-menu button#menu-button11 {
  font-size: 13px;
  width: 250px;
  line-height: 33px;
}

/* When you mouse over the navigation links, change their color */

#mobile-menu .sidenav a:hover,
.offcanvas a:focus {
  color: #f1f1f1;
}

/* Position and style the close button (top right corner) */

#mobile-menu .sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/*------------------------------------Mobile navigation end -------------------------*/

.dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTables_wrapper #members_list_table_paginate {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

table#members_list_table {
  border-bottom: 1px solid #ddd;
}

.dataTables_wrapper table.dataTable thead th,
table.dataTable thead td,
table.dataTable.no-footer,
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #f9fafb !important;
  border: 1px solid #dbdbdb !important;
}

.max-dev-w {
  width: 26rem;
}

.loading-loader {
  opacity: 0.5;
  position: relative;
}

.loading-loader::after {
  background: url(/assets/images/ajax-loader.gif);
  position: absolute;
  z-index: 100000000000000020;
  width: 100%;
  left: 0;
  content: '';
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  top: 30%;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 4px;
}

table#year tr,
table#month tr {
  cursor: pointer;
}

.f-12 {
  font-size: 12px;
}

/*Design Improve Css*/

.w-369 {
  width: 369px;
}

.w-cst {
  width: 29px;
}

/*==================SURVEY QUESTION CSS ========================*/

.qus-container button.w-1\/5 {
  width: 63px;
  height: 63px;
  padding: 7px;
  line-height: 46px;
}

.qus-container .showr {
  display: block !important;
}

.qus-container .w-387 {
  width: 387px;
}

.qus-container.cc {
  display: table;
}

.qus-container button.w-1\/10 {
  width: 29px;
}

.qus-container .data-logic-bar {
  position: relative;
  cursor: pointer;
}

.qus-container .top-bar:before {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  border-top: 1px solid #c4c4c4;
  width: 387px;
  left: -28px;
}

.qus-container.active .top-bar:before {
  width: 332px;
}

.qus-container .data-logic-btn {
  font-size: 14px;
  color: #141921;
}

.qus-container .float-right {
  float: right;
}

.qus-container .float-left {
  float: left;
}

.qus-container .action-data-rule,
.qus-container .action-data-logic {
  color: #2d4eff;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.qus-container .data-loggic-toggle-content {
  display: none;
}

.qus-container .content-show {
  display: block;
  text-align: left;
}

.qus-container .action-data-logic,
.qus-container .arw-dwn {
  cursor: pointer;
}

.qus-container .up-arrow {
  transform: rotateX(180deg);
}

.qus-container .data-rule-info,
.qus-container .add-logic-info {
  background: #f4f3f4;
  float: left;
  width: auto;
  padding: 12px 13px;
  border-radius: 5px;
  font-size: 12px;
}

.qus-container .data-rule-info:hover {
  background: #dbe1ff;
  color: #2d4eff;
}

.qus-container .data-logic-info:hover {
  background: #dbe1ff;
  color: #2d4eff;
}

.qus-container .data-logic-info:hover svg path {
  fill: #2d4eff;
}

.qus-container .data-rule-info:hover svg {
  outline: #2d4eff;
}

.qus-container .data-rule-info:hover .on-hover {
  opacity: 1;
}

.qus-container .data-logic-info:hover .on-hover {
  opacity: 1;
}

.qus-container .crs2 {
  position: relative;
  z-index: 90;
  cursor: pointer;
}

.qus-container .on-hover {
  opacity: 0;
}

.qus-container .w-266 {
  width: 266px;
}

.qus-container .data-rule-info,
.qus-container .add-logic-info,
.qus-container .property-name-modal,
.qus-container .data-logic {
  display: none;
}

.qus-container .data-rule-info.show,
.qus-container .add-logic-info.show,
.qus-container .property-name-modal.show,
.qus-container .data-logic.show,
.qus-container .logic_first_step.show {
  display: block !important;
}

.qus-container .input-29 {
  height: 29px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 25px;
}

.qus-container .h-29 {
  height: 29px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 25px;
}

.qus-container .h-21 {
  height: 21px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 18px;
}

.qus-container .w-full-l {
  float: left;
  width: 100%;
  height: 100%;
}

.qus-container .crs {
  position: relative;
  z-index: 22;
  cursor: pointer;
}

.qus-container .top-20 {
  top: 5.5rem;
}

.qus-container .data-logic-info {
  background: #f4f3f4;
  float: left;
  width: auto;
  padding: 12px 13px;
  border-radius: 5px;
  font-size: 11.5px;
  display: none;
  cursor: pointer;
}

.qus-container .data-rule-info.add-logic-info.mb-2.show,
.qus-container .add-logic-info.add-logic-info.mb-2.show {
  background: #dbe1ff;
  color: #2d4eff;
  align-items: center !important;
}

.qus-container .icon-center {
  align-items: center;
}

.qus-container .hide {
  display: none;
}

.qus-container .show {
  display: block;
}

.qus-container .br-l {
  padding-right: 0 !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  max-width: 334px;
  letter-spacing: -0.25px;
}

.qus-container .br-r {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.qus-container .havefilter .data-logic-btn {
  background: -webkit-linear-gradient(
    rgba(0, 71, 255, 1),
    rgba(189, 0, 255, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.qus-container .show-then {
  display: none;
}

.qus-container .havefilter .hide-then {
  display: none;
}

.qus-container .havefilter .show-then {
  display: block !important;
}

.qus-container .h-130 {
  max-height: 117px;
  left: -1px;
  width: 234px;
  border: 1px solid #e5e5e5;
}

.qus-container .h--150 {
  display: block;
  height: 165px;
}

.qus-container .h--150.block-1 {
  height: 46px;
}

.qus-container .h--150.block-2 {
  height: 77px;
}

.qus-container .h--150.block-3 {
  height: 128px;
}

.qus-container .h--150.block-1.ans_apply {
  height: 83px;
}

.qus-container .h--150.block-2.ans_apply {
  height: 114px;
}

.qus-container .h--150.block-3.ans_apply {
  height: 165px;
}

.qus-container .self-top {
  position: relative;
  top: 3px;
}

.qus-container .text-uppercase {
  text-transform: uppercase;
}

/*-----DEVELEPOR CSS----*/

.qus-container .disabled {
  opacity: 0.5;
  pointer-events: none;
}

.qus-container .error p {
  color: #ff000082;
  width: 100%;
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: -7px !important;
}

.qus-container .h-min-29 {
  min-height: 29px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 25px;
}

.qus-container .self-top.mb-13 {
  margin-bottom: 10px;
}

.qus-container .fl-wl {
  float: left;
  width: 100%;
}

.qus-container .data-logic.top-20,
.qus-container .logic_first_step.top-28 {
  /*top: 5.5rem;*/
  top: calc(100% - 30px);
}

.page-rule,
.audience-rule,
.event-rule {
  color: #2d4eff;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

/*Custom tagify Css*/

.tagify {
  margin-top: 10px;
  max-width: 530px;
  display: flex;
  position: relative;
  background: #f4f3f4;
  float: left;
  width: auto;
  padding: 12px;
  border-radius: 5px;
  font-size: 12px;
}

html body .tagify-value {
  font-size: 12px !important;
  color: #000 !important;
  line-height: 20px;
  letter-spacing: 0px;
}

.tagify:after {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  content: '';
  right: 6px;
  background: url('assets/media/cross.svg');
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  top: 17px;
}

.tagify:before {
  opacity: 0;
  right: 0;
  top: 0;
  border-radius: 0 5px 5px 0;
  position: absolute;
  content: '';
  background-image: linear-gradient(to right, rgba(219, 225, 255, 0), #dbe1ff);
  padding: 14px;
  height: 43px;
  width: 40px;
}

.tagify:hover {
  background: #dbe1ff;
  color: #2d4eff;
}

.tagify:hover p {
  color: #2d4eff !important;
}

.tagify:hover:before,
.tagify:hover:after {
  opacity: 1;
}

@media (max-width: 1440px) {
  html body .tagify-value {
    letter-spacing: -0.25px;
  }
}

/*End Here*/

/*==============================SURVEY SETTING SMART AUDIENCE START=====================================*/

.survey_settings .rating_btn.five-star {
  display: flex;
}

.survey_settings .rating_btn.five-star button {
  padding-left: 11px !important;
  padding-right: 10px !important;
}

.survey_settings .checkedCheckBox {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  background-color: #2563eb;
}

.survey_settings .checkedRadio {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border: 6px solid #2d4eff !important;
}

.survey_settings .hide-2 {
  display: none;
}

.survey_settings .tagify_input_container {
  display: inline-flex;
  padding: 1px;
  border: 1px solid #ddd;
  width: 100%;
}

.survey_settings .tagify_input_container .tag {
  padding: 8px 4px;
  margin-left: 0px;
}

.survey_settings .tagify_tag_container .tag:first-child {
  margin-left: 0px;
}

.survey_settings .tagify_input_container .tag {
  width: 100%;
}

.survey_settings .tagify_input_container .tag:focus-visible {
  border: 0px !important;
  outline: none;
}

.survey_settings ul.tagify_tag_container li {
  display: inline-block;
  background: #ccc;
  padding: 2px 24px 4px 6px;
  margin-right: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

.survey_settings .delete-btn:hover {
  stroke: red;
}

.survey_settings .f-16 {
  font-size: 16px;
}

.survey_settings .w-266 {
  width: 266px;
  float: left;
}

.survey_settings .border-top-0 {
  border-top: 0;
}

.survey_settings .event_drop_list {
  max-height: 403px;
  /* min-height: 200px;*/
  overflow-y: auto;
}

.survey_settings .scrollbar {
  scrollbar-width: 10px !important;
}

.survey_settings .h-430 {
  max-height: 300px;
}

.survey_settings .h-320 {
  max-height: 320px;
}

.survey_settings .firstseen-dropdown,
.survey_settings .time-rule-dropdown {
  display: none;
}

.survey_settings .country-rule-info,
.survey_settings .page-rule-info,
.survey_settings .time-rule-info,
.survey_settings .firstseen-info,
.survey_settings .common-rule-info,
.survey_settings .event-rule-info {
  display: none;
  position: relative;
  background: #f4f3f4;
  float: left;
  width: auto;
  padding: 9px 12px;
  border-radius: 5px;
  font-size: 12px;
  min-height: 40px;
}

.survey_settings .common-rule-info:hover,
.survey_settings .firstseen-info:hover,
.survey_settings .country-rule-info:hover,
.survey_settings .event-rule-info:hover,
.survey_settings .page-rule-info:hover,
.survey_settings .time-rule-info:hover {
  background: #dbe1ff;
  color: #2d4eff;
}

.survey_settings .crs2 {
  opacity: 0;
  position: absolute;
  right: 0px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    rgba(219, 225, 255, 0),
    rgba(219, 225, 255, 1)
  );
  padding: 14px;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  top: -4px;
  border-radius: 0 5px 5px 0;
}

.survey_settings .firstseen-info.show,
.survey_settings .firstseen-dropdown.show,
.survey_settings .country-rule-info.show,
.survey_settings .country-rule-dropdown.show,
.survey_settings .cal-click.show,
.survey_settings .actual-event-dropdown.show,
.survey_settings .addpage_rule.show,
.survey_settings #choice2.show,
.survey_settings #curntpage.show,
.survey_settings .url-rule-dropdown.show,
.survey_settings .page-rule-info.show,
.survey_settings .time-rule-info.show,
.survey_settings .time-rule-dropdown.show,
.survey_settings .common-rule-info.show,
.survey_settings .common-rule-dropdown.show,
.survey_settings .recurring_new.show,
.survey_settings .event-rule-info.show {
  display: block;
}

.survey_settings .common-rule-info:hover svg path,
.survey_settings .time-rule-info:hover svg path,
.survey_settings .page-rule-info:hover svg path,
.survey_settings .firstseen-info:hover svg path,
.survey_settings .country-rule-info:hover svg path,
.survey_settings .event-rule-info:hover svg path {
  fill: #2d4eff;
  border-color: #2d4eff;
}

.survey_settings .common-rule-info:hover .crs2,
.survey_settings .time-rule-info:hover .crs2,
.survey_settings .page-rule-info:hover .crs2,
.survey_settings .firstseen-info:hover .crs2,
.survey_settings .country-rule-info:hover .crs2,
.survey_settings .event-rule-info:hover .crs2 {
  opacity: 1;
}

.survey_settings .time-rule-info:hover svg.icon-tabler-clock {
  stroke: #2d4eff;
}

.survey_settings .time-rule-info:hover svg.icon-tabler-clock path {
  fill: none !important;
}

.survey_settings #curntpage,
.survey_settings .url-rule-dropdown,
.survey_settings #choice2,
.survey_settings .addpage_rule,
.survey_settings .actual-event-dropdown,
.survey_settings .country-rule-dropdown,
.survey_settings .audience-rule-dropdown,
.survey_settings .cal-click,
.survey_settings .days.hide,
.survey_settings .recurring_new,
.survey_settings .common-rule-info,
.survey_settings .common-rule-dropdown,
.survey_settings .event-rule-info.hide {
  display: none;
}

.survey_settings svg.icon-x {
  position: absolute;
  right: 5px;
  bottom: 7px;
  cursor: pointer;
}

.survey_settings .self-top {
  margin-top: 3px;
}

.survey_settings .h-21 {
  height: 21px;
}

.survey_settings .list-cntry {
  position: relative;
  left: 0px;
  top: 0px;
  border-top: 0;
  width: 235px;
}

.survey_settings .f-12.text-black {
  font-size: 12px !important;
  color: #000 !important;
}

.survey_settings input[type='number']::-webkit-outer-spin-button,
.survey_settings input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.survey_settings input[type='number'] {
  -moz-appearance: textfield;
}

.survey_settings .desktop-popup h2 {
  font-size: 28px !important;
}

.survey_settings .days-event.show,
.survey_settings .DATE-click.show,
.survey_settings .event-tab.show,
.survey_settings #data_val.show,
.survey_settings #data_val_2.show,
.survey_settings .auto.show,
.survey_settings #data.show {
  display: block;
}

.survey_settings .DATE-click,
.survey_settings .event-tab,
.survey_settings .days-event,
.survey_settings #data_val,
.survey_settings #data_val_2 {
  display: none;
}

.survey_settings .cursor-pointer.leading-loose {
  text-align: center !important;
}

.survey_settings .w-full-l {
  float: left;
}

.survey_settings .act,
.survey_settings .act2 {
  font-weight: 500;
  cursor: pointer;
}

.survey_settings .act:hover,
.survey_settings .act2:hover {
  color: #2d4eff;
}

.survey_settings .disabled {
  pointer-events: none;
  color: #9495b6;
}

.survey_settings ul.event_drop_list li {
  display: flex;
  align-items: center;
  justify-content: start;
}

.survey_settings ul.event_drop_list li svg {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.survey_settings .firstseen-info:hover .tagify-value {
  color: #2d4eff !important;
}

.survey_settings .w-238 {
  width: 238px;
}

.survey_settings .time-select option:checked {
  font-weight: 700;
  color: #000;
}

.survey_settings .text-own p {
  color: #262c38 !important;
  font-size: 12px !important;
}

.survey_settings .w-97 {
  width: 97px;
}

.survey_settings .custom-field-second,
.survey_settings .custom-field-first,
.survey_settings .text-own {
  display: none;
}

.survey_settings .custom-field-first.show,
.survey_settings .custom-field-second.show,
.survey_settings .text-own.show {
  display: block;
}

.survey_settings .input-29 {
  height: 29px;
  padding: 6px 13px;
  font-size: 12px;
  line-height: 9px !important;
}

.survey_settings .h-29 {
  height: 29px;
  font-size: 12px;
  line-height: 28px;
}

.survey_settings input[type='radio']:focus {
  outline: none !important;
}

.process-running {
  background-image: url('/assets/images/ajax-loader.gif') !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  font-size: 0px !important;
  background-size: 153px !important;
  pointer-events: none;
}

.process-running svg {
  display: none;
  pointer-events: none;
}

.z-index-1 {
  z-index: -1;
}

.other_tooltip {
  min-width: 182px;
  position: absolute;
  left: -63px;
  top: -31px;
}

.qus-container .custom_add_choice {
  display: none;
}

.qus-container.active .custom_add_choice {
  display: flex;
  margin-bottom: 16px;
}

.survey_settings .w-586 {
  width: 586px;
}

.survey-icons {
  min-width: 11px;
}

.new-f-18 {
  font-weight: 700;
  font-size: 18px;
}

.for-mob {
  width: calc(100% - 34px);
  margin: 0 17px 18px 17px;
  border-radius: 5px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 25%);
  border-bottom-right-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    width: 100%;
    margin: 0 0 18px 0;
  }
}

.mt-23 {
  margin-top: 23px !important;
}

.survey-preview-mobile-container h2 {
  padding-right: 25px;
  line-height: 22px !important;
}

button.new-f-18 {
  border: none;
  background: var(--1flow-text-color-05, #f3f3f3);
  border-radius: 5px;
  width: 50.74px !important;
  height: 48px !important;
  padding: 0;
  line-height: 45px !important;
  margin-right: 8.55px;
}

.full-size app-rating-numerical-mobile .mobile-main-container button.new-f-18 {
  width: 56.74px !important;
}
.preview-option-bg-color {
  background-color: var(--1flow-text-color-05, #f3f3f3) !important;
  border-color: var(--1flow-text-color-05, #f3f3f3) !important;
}
.survey-popup .selected {
  background: var(--1flow-text-color-05, #f3f3f3);
  border: 2px solid #5f5fef;
}
.mr-0.new-f-18 {
  margin-right: 0px !important;
}

.gry-txt-new {
  color: #878787 !important;
}

.new-mt-23 {
  margin-top: 23px !important;
  margin-left: 19px;
  margin-right: 19px;
}

.new-mt-5 {
  margin-top: 11px !important;
}

.new-mb-17 {
  margin-bottom: 17px;
  margin-right: 19px;
  margin-left: 21px;
}

.pull-top {
  height: 5px;
  position: relative;
  top: 0px;
}

.user-info {
  height: 55px;
}

img.user-icn {
  width: 40px;
  margin-right: 5px;
}

.area-for {
  position: relative;
  display: flex;
  align-items: center;
}

span.circle-green {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 30px;
  left: 30px;
  background: #02e834;
  border: 2px solid #ffffff;
  border-radius: 100px;
}

.bdr-100 {
  border-radius: 100px;
}

.new-w-47 {
  width: 47.67px !important;
  height: 47.67px !important;
  padding: 10px;
  margin-right: 10px;
  line-height: 31px !important;
}

.new-w-47.mr-0 {
  margin-right: 0 !important;
}

.w-278 {
  width: 278.33px;
  padding: 0 0 18px 0 !important;
  margin: 0 auto;
}

.pd-lf-22 {
  padding-left: 22px;
}

.survey-preview-mobile-container .small-size {
  max-width: 370px;
}

.mockup-phone .display {
  border-radius: 15px;
}

.mockup-phone {
  border-radius: 30px;
}

.new-ft {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular' !important;
  font-weight: 500 !important;
  line-height: 21px;
}

.survey-preview-mobile-container .h-590 {
  height: 710px;
  overflow: hidden;
}

.new-w-18 {
  width: 22px;
  height: 22px;
}

.rat-text {
  font-size: 12px !important;
  line-height: 14px !important;
  color: #878787 !important;
  text-align: center !important;
}

.w-199 {
  width: 199px;
  margin: 0 auto;
  margin-bottom: 12px;
}

.new-w-37 {
  width: 37px;
}

.sp-22-15-17 {
  margin: 15px 17px 0 22px;
}

.m-10-15 {
  margin: 10px 0 15px 0;
}

.gray {
  background: #c4c4c4 !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.full-width {
  width: 100% !important;
}

.pl-19-11 {
  padding: 11px 19px 0 19px;
}

.set-it-self {
  top: 25px;
  right: 16px;
}

.new-size-input:hover,
.new-size-input:focus {
  outline: none !important;
}

button.new-f-18:focus,
button.new-f-18:hover {
  color: #fff !important;
}

button.full-width {
  height: 38px !important;
  line-height: 13px !important;
}

.skip-txt {
  cursor: pointer;
}

.qus-container .rating-star.current,
.qus-container .rating-star.active,
.qus-container .rating-star.current path,
.qus-container .rating-star.active path {
  stroke: rgba(45, 78, 255, 1) !important;
  fill: rgba(45, 78, 255, 1) !important;
  --tw-bg-opacity: 1;
}

.z-60 {
  z-index: 60;
}

/*============SURVEY TEMPLATE CSS ==========*/

.survey-template-container nav {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .bg-emerald2-300 {
  background-color: #91ecc0;
}

.survey-template-container .rounded-lg-10 {
  border-radius: 10px;
}

.survey-template-container .w-300 {
  width: 300px;
}

.survey-template-container .dark-link {
  color: #141921;
}

.survey-template-container .Gray-Text {
  color: #50555c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex-wrap: wrap;
}

.survey-template-container .w-320 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 15%);
}

.survey-template-container .font-20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}

.survey-template-container .font-16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}

.survey-template-container .browse {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #141921;
  margin: 48px 0 16px;
}

.survey-template-container .height-72 {
  height: 72px;
}

.survey-template-container .pc-img {
  width: 40px;
  height: 40px;
  margin: auto;
}

.survey-template-container .Mt-32 {
  margin-top: 32px;
}

.survey-template-container .Mt-24 {
  margin-top: 24px;
}

.survey-template-container .Mb-60 {
  margin-bottom: 34px;
}

.survey-template-container .font-family-semibold {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-semibold';
}

.survey-template-container .font-family-regular {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

@media (min-width: 1440px) and (max-width: 1600px) {
  .template-list .grid-col-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .template-list .grid-col-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.template-list .cross-img {
  width: 16px !important;
  height: 16px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  left: 40px;
}

// h2#slide-over-title:not(.thanks) {
//     padding-right: 40px;
// }

@media (max-width: 630px) {
  .template-list .cross-img {
    left: 0;
  }
}

/*============SURVEY TEMPLATE CSS END==========*/

/*============SURVEY PREVIEW CSS ==========*/

.survey-template-container .survey-preview {
  background: #f4f3f4;
  border-radius: 10px;
  padding: 16px;
  height: calc(100vh - 140px);
}

.survey-template-container .preview {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #141921;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-semibold';
}

.survey-template-container .back-icon {
  width: 6px;
  height: 10px;
  margin-right: 12px;
}
.survey-template-container .back-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #50555c;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .bar {
  margin-right: 21px;
}

.survey-template-container .side-cont h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #141921;
}

.survey-template-container .side-cont p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #50555c;
}
.survey-template-container .w-300 {
  width: 300px;
  background: #f4f3f4;
}

.survey-template-container .buttons button {
  background: #eef4fe;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #2d4eff;
  padding: 2px 8px;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .use-temp {
  width: 268px;
  height: 40px;
  background: #2d4eff;
  border: 1px solid #2d4eff;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .font-family-semibold {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-semibold';
}
.survey-template-container .font-family-regular {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .para {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #50555c;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
  margin-left: 16px;
  margin-right: 16px;
}
.survey-template-container .start {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #50555c;
  margin-top: 22px;
  display: inline-block;
  text-align: center;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.survey-template-container .mockup-window {
  background: #c4c4c4;
  height: calc(100vh - 232px);
}

.survey-template-container .dark-link-text1 input {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #141921;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-semibold';
  background: transparent;
  margin-left: 16px;
  width: 268px;
}

.survey-template-container .dark-link-text2 input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #50555c;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
  background: transparent;
  margin-left: 16px;
  width: 268px;
}

/*============SURVEY PREVIEW CSS END ==========*/

@media (min-width: 1024px) and (max-width: 1440px) {
  .template-list .cross-img {
    position: relative;
    left: 72px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1600px) {
  .template-list .cross-img {
    position: relative;
    left: 72px !important;
  }

  .survey-template-container .w-320 {
    width: 100%;
  }
  .survey-template-container .grid-col-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}
@media (min-width: 1600px) and (max-width: 1920px) {
  .survey-template-container .grid-col-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}
.template-list .cross-img {
  margin-top: 0px !important;
}
@media (min-width: 1600px) and (max-width: 1920px) {
  .template-list .cross-img {
    margin-top: 0px;
    left: 72px !important;
    position: relative;
  }

  .survey-template-container .grid-col-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.survey-template-container.cross-img {
  width: 16px !important;
  height: 16px;
  margin-top: 10px;
}

@media (min-width: 625px) and (max-width: 1024px) {
  .mobile-col.grid-cols-3 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .mobile-pad {
    padding-left: 50px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .mobile-bar {
    position: fixed;
    height: 100%;
  }
  .template-list-sidebar {
    position: fixed;
    height: 100%;
  }
  .survey-template-container .w-300 {
    height: 100%;
  }
  .template-list .cross-img {
    margin-top: 10px !important;
  }
  .D-none {
    display: block !important;
  }
  .template-list .cross-img {
    left: 10px;
    top: -4px;
    position: relative;
  }
  .M-17 {
    margin: 0 17px;
  }

  .template-preview-sidebar {
    position: fixed;
    z-index: 9999;
    height: 100%;
  }

  .template-list .cross-img {
    position: relative;
  }
}
.D-none {
  display: none;
}

.gap-4.Grids-Size.grid-col-4 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

@media (min-width: 645px) and (max-width: 768px) {
  .P-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*=========================new signup flow ==================*/

.new-signup .sidebar-logo {
  margin-bottom: 57px;
}

.new-signup.main {
  font-family: 'Conv_FontsFree-Net-SFProDisplay-regular';
}

.new-signup .left_heading {
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}

.new-signup .mb-39 {
  margin-bottom: 39px !important;
}

.new-signup .progress-heading {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.new-signup .round-tick {
  width: 29px;
  height: 29px;
  background: #2d4eff;
}

.new-signup .border-Blue {
  border-color: #2d4eff;
}

.new-signup .without-active {
  width: 29px;
  height: 29px;
}

.new-signup .without-active-inner {
  width: 29px;
  height: 29px;
  border: 1px solid #2d4eff;
}

.new-signup .inner-circle {
  width: 9px;
  height: 9px;
  background: #2d4eff !important;
}

.new-signup .without-active-inner2 {
  width: 29px;
  height: 29px;
  margin-top: 6px;
}

.new-signup .right-heading {
  font-weight: 800;
  font-size: 22px;
  line-height: 26px;
}

.new-signup .sub-heading {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #50555c;
  margin-top: 5px;
}

.new-signup .w-322 {
  width: 322px;
}

.new-signup .h-44 {
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.new-signup .h-62::placeholder {
  color: #878787;
}
.new-signup .Lable {
  color: #50555c;
  line-height: 19px;
}

.new-signup .mt-28 {
  margin-top: 28px;
}

.new-signup .w-365 {
  width: 365px;
}

.new-signup .plus {
  width: 16px;
  height: 16px;
  background: #141921;
  border-radius: 34px;
  text-align: center;
  color: #fff;
  font-weight: bolder;
  font-size: 15px;
  line-height: 13px;
  margin-right: 8px;
}

.new-signup .add-another-btn {
  border: 1px solid #50555c;
}

.new-signup .skip-btn {
  width: 106px;
  color: #50555c;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.sendinvite_btn {
  width: 196px;
  height: 35px;
  background: #2d4eff;
  padding-left: 38px;
}

.new-signup .select-role {
  color: #9ca3c1;
  font-size: 15px;
  padding: 12px 18px;
  width: 164px;
}

.new-signup .get_ready {
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px !important;
  margin-bottom: 33px !important;
}

.new-signup .refresh-cont {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.new-signup .refresh {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d4eff;
  margin-top: 35px;
}

.new-signup .mt-100 {
  margin-top: 100px;
}

.new-signup .mb-100 {
  margin-bottom: 100px;
}

.new-signup .backbtn {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 92px;
  height: 35px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #141921;
  align-items: center;
}

.new-signup .need-help {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #262c38;
}

.new-signup .need-help a {
  font-weight: bold;
  color: #2d4eff;
}

.new-signup .get_ready {
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px !important;
  margin-bottom: 33px !important;
  padding-right: 12px !important;
}

.new-signup .bg-Blue {
  background: #2d4eff;
}

.new-signup .cong-cont {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
}

.new-signup .cong-cont-para {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: center;
  margin-top: 17px;
}

.new-signup .dashbtn {
  width: 205px;
  height: 35px;
  background: #2d4eff;
  margin-top: 34px;
  padding-left: 18px;
}
@media (max-width: 462px) {
  .new-signup .mobile-logo-center {
    margin: auto;
  }

  .new-signup .w-365 {
    width: 100%;
  }

  .new-signup .mobile-flex-wrap {
    flex-wrap: wrap;
  }

  .new-signup .select-role {
    width: 100%;
    margin-left: 0 !important;
  }

  .new-signup .flex-wrap-btn {
    flex-wrap: wrap;
    justify-content: center;
  }

  .new-signup .w-322.mt-37.role-field {
    width: 162px;
    margin-top: 11px;
  }

  .new-signup .mobile-center {
    text-align: center;
    margin-right: 0;
  }

  .new-signup .left_heading {
    justify-content: center;
  }

  .new-signup nav {
    margin-left: 30px;
  }

  .new-signup .w-322.mt-37.role-field label {
    margin-left: 0;
  }

  .new-signup .margin-mobile {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .new-signup .h-62 {
    width: 100%;
    padding: 70px 20px;
  }
  .new-signup .refresh-cont {
    flex-direction: column;
  }

  .new-signup .refresh {
    margin-top: 15px;
  }

  .new-signup .need-help {
    margin-left: 10px;
  }
  .new-signup .mobile-logo-center {
    margin: auto;
  }
}

.new-signup .sendinvite_btn {
  width: 196px;
  height: 35px;
  background: #2d4eff;
  padding-left: 38px;
}

.new-signup .test-sdk-btn {
  width: 220px;
  height: 35px;
  background: #2d4eff;
  padding-left: 14px;
}

.new-signup .select-role {
  color: #9ca3c1;
  font-size: 15px;
  padding: 14px 18px;
  width: 164px;
}

.new-signup .bedge-cont {
  color: #0061d5;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.new-signup .h-62 {
  height: 62px;
  background: #f0f6fd;
  border-radius: 5px;
}

.new-signup .copy-text {
  font-weight: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0061d5;
}

.new-signup .sdk-box {
  border: 1px solid #c4c4c4;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 204px;
  height: 68px;
  padding: 12px 15px;
}

.new-signup .sdk-box h3 {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.new-signup .get_ready {
  color: black;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px !important;
  margin-bottom: 33px !important;
}

.new-signup .loader {
  animation-name: rotate-signup-loader;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;
}

@keyframes rotate-signup-loader {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .new-signup .mobile-flex {
    flex-wrap: wrap !important;
  }

  .new-signup .margin-mobile {
    margin-top: 30px;
    width: 100%;
    margin-bottom: 30px;
  }

  .new-signup .sdk-box {
    width: 100%;
  }
  .new-signup .h-62 {
    width: 100%;
  }

  .new-signup .sdk-box {
    width: 100%;
  }
}

@media (max-width: 344px) {
  .new-signup .need-help {
    margin-top: 10px;
  }
}

.new-signup .sdk-box:hover {
  border: 1px solid #0061d5;
}
.new-signup .sdk-box:hover h3 {
  color: #0061d5;
}
.survey_settings .pickr .pcr-button::after {
  border: 1px solid #ddd;
  border-radius: 100%;
}
.survey_settings .pickr .pcr-button::before {
  left: 0.5px;
  top: 0.5px;
  width: 95%;
  height: 95%;
  border-radius: 100%;
}
.survey_settings .pickr .pcr-button:focus {
  box-shadow: unset !important;
}

/*==================NEW APPEARENCE DESIGN CSS START ===========*/

/*==================NEW APPEARENCE DESIGN CSS END ===========*/

/*.center-center-full:before {position: absolute; content: ''; width: 100vw; height: 100vh; background: #fff; top: -81%; left: -39%; z-index: 0; display: block;}
.center-center-full .w-screen.w-369 { width: 609px !important; position: relative;}*/
.middle-top,
.middle-bottom {
  margin: auto auto 0 auto !important;
  width: 100%;
  display: inline-table;
  background: #fff;
}
.middle-top {
  margin: 0 auto auto auto !important;
}
.middle-top .main-qus-container,
.pop-full .main-qus-container,
.middle-bottom .main-qus-container {
  width: 100% !important;
  border-radius: 0px !important;
  box-shadow: none;
}
.middle-top .w-screen.w-369,
.middle-bottom .w-screen.w-369 {
  margin: 0 auto;
}
.middle-top .w3-border,
.middle-bottom .w3-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.top-only {
  margin: 20px auto auto auto !important;
}
.bottom-only {
  margin: auto auto 20px auto !important;
}

.survey-popup button.close_svg.full-view {
  position: absolute;
  right: 17px;
  top: 21px;
  z-index: 22;
}

.survey-popup .main-qus-container:not(.for-mob) {
  margin-bottom: 10px;
  overflow: hidden;
}
.pop-full .grid.grid-rows-1.grid-flow-col.gap-6.pdl-20.pdr-20.mb-4 {
  margin-bottom: 0;
}
.top-left .w-screen.w-369.ml-2.right-sidebar {
  margin-left: 0 !important;
}

.font-12 {
  font-size: 12px !important;
}
/*For Mobile*/

.full-size.for-mob {
  margin: 0;
  width: 100%;
  border-radius: 0 !important;
}
html body .full-size .w-custom {
  width: 25px !important;
  line-height: 47px !important;
}
.bottom-unset.full-size .self-w-370 {
  width: 370px !important;
}
.survey-popup .txt_cls_60 {
  font-weight: 400 !important;
}

/* End*/

/*END*/
/*.survey-preview .full-view{
    display: none;
}*/
.survey_settings .pickr .pcr-button:focus {
  box-shadow: unset !important;
}

.survey_settings .accordion-header button {
  background: unset;
  width: 100%;
  text-align: left;
  float: left;
  color: #000;
}
.survey_settings .accordion-header .tab-description {
  margin-top: 18px !important;
}

.survey_settings .accordion-header button svg {
  position: absolute;
  right: 19px;
  top: 32px;
}

.survey_settings .accordion-header {
  float: left;
  width: 100%;
}

.survey_settings .accordion-header button[aria-expanded='true'] svg {
  transform: rotate(180deg);
  color: $primary-color;
}

.survey_settings .accordion-header button[aria-expanded='true'] {
  color: $primary-color;
}

.accordion-icon-img {
  max-width: 30px;
  float: left;
  margin-right: 10px;
}

.accordion-content {
  float: left;
  width: 100%;
}

.middle-screen button.new-f-18 {
  width: 19.6% !important;
  margin-right: 0.5%;
}

.small-toggle {
  width: 32px !important;
  height: 20px !important;
}
.small-pointer {
  width: 16px !important;
  height: 16px !important;
}
.small-pointer.translate-x-5 {
  --tw-translate-x: 0.9rem !important;
}

.progress-bar {
  overflow: hidden;
  max-width: 100%;
}

.progress-bar .w3-grey {
  transition: width 0.5s;
}

// .mobile-main-container {
//     max-height: 62vh;
//     overflow-y: scroll;
// }

.middle-bottom .shadow-xl,
.middle-top .shadow-xl {
  box-shadow: unset;
}

.not-show-f .full-view {
  display: none;
}
.not-show-f .mobile-main-container {
  overflow: unset !important;
}
.not-show-f br {
  display: none;
}
.question-container {
  max-height: 100%;
  overflow-y: auto;
  margin: 0 auto !important;
  max-width: 572px;
}

.pop-full .question-container {
  transform: scale(0.8);
}

.survey-required-err img,
.survey-required-success img {
  margin-top: 3px;
  margin-right: 2px;
}
.survey-required-err,
.survey-required-success {
  font-size: 12px;
  font-family: 'Conv_FontsFree-Net-SFProDisplay-Regular';
  font-weight: 400;
  color: #e20000;
}
.survey-required-success {
  color: #56c290;
}

// .preview-empty-div{
//     width:var(--preview-emtpy-div-width);
//     height:var(--preview-emtpy-div-height);;
//     background-color: var(--preview-emtpy-div-color,#fff);
//     border-radius: 5px 5px 0px 0px !important;
// }
.z-2-in {
  z-index: 2;
}

/*========MOBILE PREVIEW SCREEN=====*/
.settings .pickr .pcr-button::after {
  border: 1px solid #ddd;
}

.settings .pickr .pcr-button::after {
  border-radius: 100% !important;
}
.settings .pickr .pcr-button::before {
  left: 0.5px;
  top: 0.5px;
  width: 95%;
  height: 95%;
  border-radius: 100%;
}
.not-focus:focus {
  outline: unset !important;
  border-color: transparent !important;
  outline-offset: 0px !important;
  box-shadow: none !important;
}
